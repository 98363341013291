import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import BoxHeader from '../BoxHeader/BoxHeader';
import Paragraph from '../Paragraph/Paragraph';

const Wrapper = styled.div`
  max-width: 1450px;
  margin: 100px auto;
  padding: 0;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  @media (max-width: 1450px) {
    padding: 0 40px;
  }
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
`;

const IntroText = () => {
  const wrapperRef = useRef(null);
  const barRef = useRef(null);
  const paragraphRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      [textRef.current, paragraphRef.current],
      {
        opacity: 0,
        y: '40',
      },
      {
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: 'top center+=170',
        },
        opacity: 1,
        duration: 1.75,
        y: 0,
      }
    );
    gsap.fromTo(
      barRef.current,
      { width: '0%' },
      {
        width: '35.5%',
        duration: 2.2,
        delay: 0.9,
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: 'top center+=170',
        },
      }
    );
  });

  return (
    <Wrapper ref={wrapperRef}>
      <BoxHeader barRef={barRef} textRef={textRef}>
        O NAS
      </BoxHeader>
      <Paragraph ref={paragraphRef}>
        Działamy na rynku reklamowym od kilkunastu lat. Nasz zespół to
        doświadczenie, kreatywność i wielkie możliwości w radzeniu sobie nawet z
        najbardziej nietypowymi zadaniami. Jesteśmy na bieżąco z najnowszymi
        trendami w dziedzinie reklamy, co pozwala nam oferować skuteczne
        rozwiązania marketingowe dla naszych klientów.
        <br />
        <br />
        Nasza praca obejmuje działania w tzw. zakresie full service i dzięki
        doświadczonemu zespołowi i współpracy z największymi operatorami na
        rynku reklamowym w Polsce, wcale nie jest to przesada.
        <br />
        <br />
        Specjalizujemy się w grafice reklamowej a nasz konik to design opakowań
        i produktów, Identyfikacja firm i wydarzeń. Opakowania, etykiety, logo
        to coś nad czym lubimy najbardziej pracować. Zlecenia realizujemy
        kompleksowo, skutecznie doradzamy w realizacji. Współpracujemy czynnie z
        drukarniami, introligatorami itp. Gadżety reklamowe, upominki,
        kalendarze we wszelkich formatach, specjalne akcje ta nasze naturalne
        środowisko.
        <br />
        <br />
        Najciekawsze dla nas są zadania „niemożliwe do wykonania”. My po prostu
        dajemy radę a praca nad tego typu zleceniami daje nam pełną satysfakcję.
        <br />
        <br />
      </Paragraph>
    </Wrapper>
  );
};

export default IntroText;
