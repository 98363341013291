import React from 'react';
import {Route} from 'react-router';
import {BrowserRouter, Switch} from 'react-router-dom';
import gsap from 'gsap';
import {Helmet, HelmetProvider} from "react-helmet-async"
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Homepage from 'views/Homepage';
import Contact from 'views/Contact';
import Portfolio from 'views/Portfolio';
import PageNotFound from "./PageNotFound";
import GlobalStyles from 'GlobalStyles/GlobalStyles';

const Root = () => {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.registerPlugin(ScrollTrigger);

    return (
        <>
            <HelmetProvider>
            <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Urban Advertising Brands</title>
                    <meta name="description" content="Description of the this xd app" />
                    <link rel="preconnect" href="https://fonts.gstatic.com"/>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
                        rel="stylesheet"/>
                </Helmet>
                <Switch>
                    <Route path="/" exact component={Homepage}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/portfolio" component={Portfolio}/>
                    <Route component={PageNotFound}/>
                </Switch>
                <GlobalStyles/>
            </BrowserRouter>
            </HelmetProvider>
        </>
);
};

export default Root;
