import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import gsap from 'gsap'
import MainTemplate from '../templates/MainTemplate';
import BoxHeader from '../components/BoxHeader/BoxHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import Logo from 'assets/logo.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30px - 50px);
  padding-bottom: 11%;
  margin-left: 142.5px;
  @media (max-width: 1190px) {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 8%;
  padding-left: 11%;
  @media (max-width: 470px) {
    margin-top: 14%;
  }
`;

const Span = styled.span`
  display: block;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 2.2%;
  line-height: 91%;
  letter-spacing: 1.2px;
  opacity: 0;
  @media (max-width: 1190px) {
    font-size: 2.7rem;
    margin-bottom: 2.5%;
  }
  @media (max-width: 900px) {
    font-size: 3.5rem;
    margin-bottom: 3%;
  }
  @media (max-width: 470px) {
    font-size: 2.5rem;
    margin-bottom: 4%;
  }
  @media (max-width: 380px) {
    font-size: 2.9rem;
    margin-bottom: 6%;
  }
`;

const ContentWrapper = styled.div`
  width: 45%;
  @media (max-width: 1190px) {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const LogoWrapper = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1190px) {
    width: 45%;
  }
  @media (max-width: 742px) {
    width: 65%;
  }
  @media (max-width: 450px) {
    width: 95%;
  }
`;
const Image = styled.img`
  width: 65%;
  @media (max-width: 1190px) {
    width: 100%;
  }
`;

const Contact = () => {
	const barRef = useRef(null)
	const textRef = useRef(null)
	const contentRef = useRef(null)
	const logoRef = useRef(null)
	const tl = gsap.timeline()

	useEffect(() => {
		tl.fromTo(
			textRef.current,
			{
				opacity: 0,
				y: '40',
			},
			{
				opacity: 1,
				duration: 0.75,
				y: 0,
			}
		);
		tl.fromTo(
			barRef.current,
			{width: '0%'},
			{
				width: '35.5%',
				duration: 1.75,
			}
		);
		tl.fromTo([contentRef.current.children], {x: -20, opacity: 0}, {
			x: 0,
			opacity: 1,
			duration: 0.75,
			stagger: 0.1
		}, "-=1.5")
		tl.fromTo(logoRef.current,{opacity:0},{opacity:1, duration: 1},"-=1")
	})
	return (
		<MainTemplate>
			<Wrapper>
				<ContentWrapper>
					<BoxHeader fontSize={'4.5rem'} view="contact" barRef={barRef} textRef={textRef}>
						Skontakuj się z nami
					</BoxHeader>
					<StyledParagraph ref={contentRef}>
						<Span>URBAN Advertising Brands Sp. z o.o.</Span>
						<Span>ul. Plac Wolnica 13/10</Span>
						<Span>Miasto: 31-060 Kraków</Span>
						<Span>Email: biuro@urbanab.pl</Span>
						<Span>Tel: +48 502 494 590</Span>
					</StyledParagraph>
				</ContentWrapper>
				<LogoWrapper ref={logoRef}>
					<Image src={Logo} alt={"Logo firmowey Urban Advertising Brands"}/>
				</LogoWrapper>
			</Wrapper>
		</MainTemplate>
	);
};

export default Contact;
