import React from 'react';
import styled from 'styled-components';
import PortfolioWorkElement from "../PortfiolioWorkElement/PortfolioWorkElement";
import CupImage_1 from 'assets/kubek2.jpg';
import CupImage_2 from 'assets/kubek1.jpg';
import CupImage_3 from 'assets/M_143_A.jpg';
import BannerImage from 'assets/20D.jpg';
import secondBannerImage from 'assets/20C.jpg'
import CalendarImage from 'assets/kal_ble.jpg';
import WrapperAndLabelsImage from 'assets/opakowania i etykiety.jpg';
import Chess_1 from 'assets/zawody1.jpg'
import Chess_2 from 'assets/zawody2.jpg'
import Chess_3 from 'assets/zawody4.jpg'
import polygraphy_1 from 'assets/poligrafia.jpg'
import polygraphy_2 from 'assets/poligrafia_og.jpg'

const Wrapper = styled.div`
`;

const PortfolioGrid = () => {
    const createImageObject = (imageLink, alt, objectFit) => {
        return {link: imageLink, alt, objectFit}
    }

    const wrappingAndLabels = [
        createImageObject(WrapperAndLabelsImage, "Etykiety oraz opakowania kosmetyków firmy Sqiniqa"),
    ]

    const gadgetsPensCups = [
        createImageObject(CupImage_1, "Kubek reklamowy Anshar Studios."),
        createImageObject(CupImage_2, "Kubek reklamowy Sitech z koparką oraz kołami."),
        createImageObject(CupImage_3, "Kubek reklamowy z Bramą Brandeburską."),
    ]

    const outdoorBanners = [
        createImageObject(BannerImage, "Banner reklamowy przy drodze ruchu."),
        createImageObject(secondBannerImage, "Reklama na przystanku."),
        // createImageObject(BannerImage, "Beer"),
    ]

    const posters = [
        createImageObject(CalendarImage, "Kalendarz firmowy."),
        createImageObject(Chess_1, "Komunikat organizacyjny turnieju szachowego."),
        createImageObject(Chess_2, "Plakat mistrzostw w szachach."),
        createImageObject(Chess_3, "Ulotka zawodów w szachy klasyczne."),
        createImageObject(polygraphy_1, "Ulotki, katalogi, kateczki firmowe."),
        createImageObject(polygraphy_2, "Katalogi, książeczki, ulotki reklamowe."),

    ]

    return (

        <Wrapper>
            <PortfolioWorkElement title="Opakowania i etykiety" listOfImages={wrappingAndLabels} index={0} row={1} column={1}/>
            <PortfolioWorkElement title="Gadżety, długopisy i kubki" listOfImages={gadgetsPensCups} index={1} row={1} column={3}/>
            <PortfolioWorkElement title="Outdoor" listOfImages={outdoorBanners} index={2} row={1} column={2}/>
            <PortfolioWorkElement title="Poligrafia i kalendarze" listOfImages={posters} index={3} row={2} column={2}/>
        </Wrapper>
    )
};

export default PortfolioGrid;
