import styled from 'styled-components';
import Header from '../Header/Header';

const MainBannerHeader = styled(Header)`
  font-size: 10rem;
  padding-left: 142.5px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px black;
  line-height: 10rem;
  @media (max-width: 800px) {
    padding-top: 20%;
    padding-left: 15%;
  }
  @media (max-width: 500px) {
    padding-top: 30%;
  }
  @media (max-width: 480px) {
    font-size: 7.5rem;
    padding-left: 5%;
  }
`;

export default MainBannerHeader;
