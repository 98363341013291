import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import MainTemplate from 'templates/MainTemplate';
import MainBanner from 'components/MainBanner/MainBanner';
import IntroText from 'components/IntroText/IntroText';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import MainBannerHeader from 'components/MainBannerHeader/MainBannerHeader';
import HeroImg from 'assets/gad_2.jpg';
import EndButton from '../components/EndButton/EndButton';

const BoxHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
`;

const CustomHeroHeader = styled(MainBannerHeader)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 0;
`;

const Span = styled.span`
  opacity: 0;
  display: block;
  margin-bottom: 10px;
  text-shadow: 5px 6px 8px rgba(0, 0, 0, 0.5);
`
const Homepage = () => {
    const tl = gsap.timeline();
    const HeaderBox = useRef(null);

    useEffect(() => {
        tl.fromTo([HeaderBox.current.children], {y: -20, opacity: 0}, {y: 10, delay: 0.5, opacity: 1, stagger: 0.3})
    });
    const backgroundGradient = `linear-gradient(
      to bottom,
      rgba(14, 14, 14, 0.6),
      rgba(48, 48, 48, 0.8)
    )`;
    return (
        <MainTemplate>
            <MainBanner imgSrc={HeroImg} gradient={backgroundGradient}/>
            <CustomHeroHeader ref={HeaderBox}>
                <Span>urban</Span>
                <Span>advertising</Span>
                <Span>brands</Span>
            </CustomHeroHeader>
            <IntroText/>
            <WhatWeDo/>
            <BoxHeaderWrapper>
                <EndButton destination="/portfolio">
                    Zobacz nasze przykładowe realizacje
                </EndButton>
            </BoxHeaderWrapper>
        </MainTemplate>
    );
};

export default Homepage;
