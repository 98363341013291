import React from 'react';
import PropTypes from 'prop-types'
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';

const MainTemplate = ({ children }) => (
  <>
    <Navigation />
    {children}
    <Footer />
  </>
);

MainTemplate.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
}

export default MainTemplate;
