import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  background-image: ${({ imgSrc, imgGradient }) => {
    if (imgSrc && imgGradient) {
      return `${imgGradient}, url(${imgSrc})`;
    } else {
      return `url(${imgSrc})`;
    }
  }};
  opacity: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Triangle = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 17vw solid transparent;
  border-bottom: 17vw solid rgba(51, 53, 91, 0.6);
`;

const MainBanner = ({ imgSrc, children, gradient }) => {
  const wrapperRef = useRef(null);
  const triangleRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      wrapperRef.current,
      { opacity: 0 },
      { opacity: 1,duration: 0.5 }
    );
    gsap.fromTo(
      triangleRef.current,
      { opacity: 0 },
      { opacity: 1, delay: 0.7, duration: 0.5 }
    );
  });
  return (
    <Wrapper imgSrc={imgSrc} imgGradient={gradient} ref={wrapperRef} alt={"Opakowania, etykiety, nadruki, katalogi, książeczki, zeszyty zrobione przez Urban Advertising Brands."}>
      <Triangle ref={triangleRef} />
      {children}
    </Wrapper>
  );
};

MainBanner.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  imgGradient: PropTypes.string,
};

MainBanner.defaultProps = {
  imgGradient: '',
};

export default MainBanner;
