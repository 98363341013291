import styled from 'styled-components';

const Paragraph = styled.p`
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 3rem;
  margin: 0;
  @media (max-width: 880px) {
    letter-spacing: 0.9px;
  }
  @media (max-width: 680px) {
    line-height: 2.75rem;
  }
`;

export default Paragraph;
