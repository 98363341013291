import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import BannerPhoto from 'assets/20C.jpg';
import Header from '../Header/Header';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1450px;
  height: 600px;
  background-image: url(${BannerPhoto});
  background-position: left;
  background-repeat: no-repeat;
  overflow: hidden;
  @media (min-width: 1450px) {
    margin: 0 auto;
    box-shadow: 2px 2px 7px black;
    border-top: 4px solid black;
    border-left: 4px solid black;
  }
  @media (max-width: 900px) {
    background-position: center;
  }
`;
const ContentWrapper = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: rgba(51, 53, 91, 0.85);
  padding: 40px 30px 30px 30px;
  @media (max-width: 780px) {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledHeader = styled(Header)`
  color: white;
  text-align: center;
  margin-bottom: 40px;
`;
const StyledInnerContentWrapper = styled.div`
  color: white;
  font-size: 2.4rem;
`;

const StyledUl = styled.ul`
  @media (max-width: 500px) {
    padding-inline-start: 0.5em;
  }
`;
const StyledLi = styled.li`
  margin-bottom: 10px;
`;

const WhatWeDo = () => {
	const wrapperRef = useRef();
	const contentWrapperRef = useRef();
	const innerContentRef = useRef();
	const ulRef = useRef();
	useEffect(() => {
		gsap.fromTo(
			wrapperRef.current,
			{opacity: 0, y: 50},
			{
				opacity: 1,
				duration: 2,
				y: 0,
				scrollTrigger: {
					trigger: wrapperRef.current,
					start: 'top bottom-=150',
				},
			}
		);
		gsap.fromTo(
			[ulRef.current.children],
			{opacity: 0, x: 50},
			{
				opacity: 1,
				duration: 1.2,
				x: 0,
				stagger: 0.4,
				scrollTrigger: {
					trigger: wrapperRef.current,
					start: 'top bottom-=175',
				},
			}
		);
	});
	return (
		<Wrapper ref={wrapperRef}>
			<ContentWrapper ref={contentWrapperRef}>
				<StyledHeader>Czym się zajmujemy?</StyledHeader>
				<StyledInnerContentWrapper ref={innerContentRef}>
					<StyledUl ref={ulRef}>
						<StyledLi>
							Identyfikacja firm, design opakowań, projekty graficzne dla
							wszelkich materiałów btl
						</StyledLi>
						<StyledLi>Gadżety reklamowe, nietypowe prezenty</StyledLi>
						<StyledLi>
							Produkcja poligraficzna w tym kompleksowo foldery, katalogi,
							kalendarze autorskie także w oprawach introligatorskich
						</StyledLi>
						<StyledLi>
							Social media, strony internetowe i e-sklepy (kompleksowo z
							regulaminem i polityką prywatności)
						</StyledLi>
						<StyledLi>
							kampanie reklamowe w internecie oraz prasa, radio, telewizja,
							outdoor i w miksie reklamowym
						</StyledLi>
						<StyledLi>obsługa PR wydarzeń</StyledLi>
						<StyledLi>projekty specjalne</StyledLi>
					</StyledUl>
				</StyledInnerContentWrapper>
			</ContentWrapper>
		</Wrapper>
	);
};

export default WhatWeDo;
