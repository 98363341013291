import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  &:hover {
    border-bottom: 2px solid black;
    padding-bottom: 2px;
  }
  &.active {
    border-bottom: 2px solid black;
    padding-bottom: 2px;
  }
  @media (max-width: 780px) {
    font-size: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
      padding: 0;
      border: 0;
    }
    &.active {
      background-color: black;
      color: white;
    }
  }
`;

export default NavLink;
