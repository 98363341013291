import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import BasicHeader from 'components/Header/Header';

const Wrapper = styled.div`
  position: relative;
  max-height: 75px;
  overflow: hidden;
`;
const Box = styled.div`
  position: absolute;
  z-index: -1;
  width: ${({view}) => (view === 'contact' ? '30%' : 0)};
  height: 8rem;
  background-color: rgba(80, 161, 164, 0.64);
`;

const Header = styled(BasicHeader)`
  display: inline-block;
  height: 100%;
  margin-left: 5rem;
  font-size: ${({fontSize}) => (fontSize ? fontSize : '6.5rem')};
  @media (max-width: 1190px) {
    font-size: 6.5rem;
  }
  @media (max-width: 466px) {
    font-size: 4.35rem;
  }
`;

const BoxHeader = ({children, fontSize, barRef, textRef, view}) => (
	<Wrapper>
		<Box ref={barRef} view={view}/>
		<Header fontSize={fontSize} ref={textRef}>
			{children}
		</Header>
	</Wrapper>
);
BoxHeader.propTypes = {
	fontSize: PropTypes.string,
	barRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({current: PropTypes.instanceOf(Element)})]),
	textRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({current: PropTypes.instanceOf(Element)})]),
	view: PropTypes.string
}

export default BoxHeader;
