import React from 'react';
import styled from 'styled-components'
import MainTemplate from "../templates/MainTemplate";
import Header from "../components/Header/Header";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 30px - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`



const PageNotFound = () => {
	return (
		<MainTemplate>
			<Wrapper>
				<Header>Błąd 404</Header>
				<Header>Strony nie znaleziono</Header>
			</Wrapper>
		</MainTemplate>
	);
};


export default PageNotFound;