import React from 'react';
import styled from 'styled-components';
import Paragraph from '../Paragraph/Paragraph';

const Wrapper = styled.div`
  width: 100%;
  height: 30px;
  background-color: rgba(51, 53, 91);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterParagraph = styled(Paragraph)`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  color: white;
  @media (max-width: 780px) {
    font-size: 12px;
  }
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const Footer = () => (
	<Wrapper>
		<FooterParagraph>@Copyrights Urban Advertising Brands</FooterParagraph>
	</Wrapper>
);

export default Footer;
