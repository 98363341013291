import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import MainTemplate from '../templates/MainTemplate';
import MainBanner from '../components/MainBanner/MainBanner';
import BeerBanner from 'assets/3s.jpg';
import MainBannerHeader from '../components/MainBannerHeader/MainBannerHeader';
import PortfolioGrid from '../components/PortfolioGrid/PortfolioGrid';

const SubTitle = styled(MainBannerHeader)`
  font-size: 2.2rem;
  margin-top: 20px;
  padding: 0;
  color: white;
  text-align: center;
  @media (max-width: 550px) {
    line-height: 4rem;
  }
  @media (max-width: 380px) {
    font-size: 2rem;
  }
`;

const CustomHeroHeader = styled(MainBannerHeader)`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  color: white;
  padding: 0;
`;

const Span = styled.span`
  opacity: 0;
  display: block;
  margin-bottom: 10px;
  text-shadow: 5px 6px 8px rgba(0, 0, 0, 0.5);
`

const LineSpan = styled.span`
  display: inline;
  opacity: 0;
`


const Portfolio = () => {
	const tl = gsap.timeline();

	const HeaderBox = useRef(null);
	const Subtitle = useRef(null)

	const backgroundGradient = `linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.8)
    )`;

	if (window.pageYOffset !== 0) {
		gsap.to(window, {duration: 0, scrollTo: 0});
	}

	useEffect(() => {
		// MainBanner Header animation
		tl.fromTo([HeaderBox.current.children[0], HeaderBox.current.children[1]], {y: -20, opacity: 0}, {
			y: 10,
			delay: 0.5,
			opacity: 1,
			stagger: 0.3
		})
		tl.fromTo([Subtitle.current.children], {y: -40, opacity: 0}, {y: 10, delay: 0.1, opacity: 1, stagger: 0.1})
		// End MainBanner Header animation


	});

	return (
		<MainTemplate>
			<MainBanner imgSrc={BeerBanner} gradient={backgroundGradient}/>
			<CustomHeroHeader ref={HeaderBox}>
				<Span>nasze</Span>
				<Span>realizacje</Span>
				<SubTitle ref={Subtitle}>
					<LineSpan>Zapraszamy</LineSpan>
					<LineSpan> do</LineSpan>
					<LineSpan> zapoznania </LineSpan>
					<LineSpan> się</LineSpan>
					<LineSpan> z</LineSpan>
					<LineSpan> naczymi</LineSpan>
					<LineSpan> projektami</LineSpan>
				</SubTitle>
			</CustomHeroHeader>
			<PortfolioGrid/>
		</MainTemplate>
	);
};

export default Portfolio;
