import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import gsap from 'gsap'
import Header from 'components/Header/Header';

const Wrapper = styled.div`
  margin: 10% 0;
  padding: 0 30px;
`
const StyledHeader = styled(Header)`
  opacity: 0;
  font-size: 5rem;
  text-align: center;
  text-shadow: 10px 10px 8px rgba(0, 0, 0, 0.4);
`
const ImagesWrapper = styled.div`
  margin-top: 7.5%;
  display: grid;
  grid-template-columns: ${({column}) => column ? `repeat(${column},1fr)` : "repeat(3,1fr)"};
  grid-template-rows: ${({row}) => row? `repeat(${row},1fr)` : "repeat(3,1fr)"};
  grid-column-gap: 4%;
  grid-row-gap: 40px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
  }
  
`

const Image = styled.img`
  justify-self: center;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  max-height: 900px;
  box-shadow: 12px 14px 11px rgba(0, 0, 0, 0.4);

  @media (max-width: 1400px) {
    width: 90%;
    margin: 0 auto;
	object-fit: cover;
  }
  @media (max-width: 600px) {
	width: 90%;
	margin: 0 auto;
  }

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const PortfolioWorkElement = ({title, listOfImages, index, row,column}) => {
	const wrapperRef = useRef(null)
	const ImagesWrapperRef = useRef(null)
	const HeaderRef = useRef(null)

	// Calculates animation trigger point for each element
	const animationTrigger = 400 + (index * 660)

	const tl = gsap.timeline({
		scrollTrigger: {
			trigger: wrapperRef.current,
			start: `${animationTrigger} top`,
		}
	})
	useEffect(() => {
		tl.fromTo([HeaderRef.current], {opacity: 0, y: 40}, {
			x: 0,
			y: 0,
			opacity: 1,
			duration: 0.75,
			stagger: 0.5,
			textShadow: "10px 10px 8px rgba(0, 0, 0, 0.4)"
		})
		tl.fromTo([ImagesWrapperRef.current.children], {
			y: 40,
			opacity: 0,
		}, {
			y: 0,
			opacity: 1,
			stagger: 0.2,
			duration: 0.4,
		})

	})
	return (
		<Wrapper ref={wrapperRef}>
			<StyledHeader ref={HeaderRef}>{title}</StyledHeader>
			<ImagesWrapper ref={ImagesWrapperRef} row={row} column={column}>
				{listOfImages.map(imageObject => (
					<Image src={imageObject.link} alt={imageObject.alt} key={listOfImages.indexOf(imageObject)} objectFit={imageObject.objectFit}/>
				))}
			</ImagesWrapper>
		</Wrapper>
	);
};

PortfolioWorkElement.propTypes = {
	title: PropTypes.string,
	index: PropTypes.number,
	column: PropTypes.number,
	row: PropTypes.number,
	listOfImages: PropTypes.object
}

export default PortfolioWorkElement;