import React from 'react';
import PropTypes from 'prop-types'

import styled from 'styled-components';
import Link from 'components/Link/Link';

const Wrapper = styled.div`
  margin: 0 20px;
  box-shadow: 2px 3px 10px #2e2e2e;
`;

const Button = styled(Link)`
  display: block;
  font-size: 4rem;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(80, 161, 164, 0.62);
  color: black;
  transition: all 0.3s ease-out;
  &:hover {
    padding: 10px 20px;
    border: 0;
    color: white;
    background-color: rgba(51, 53, 91, 0.9);
    text-decoration: none;
  }
  @media (max-width: 520px) {
    font-size: 2.4rem;
  }
`;

const EndButton = ({ children, destination }) => (
  <Wrapper>
    <Button to={destination}>{children}</Button>
  </Wrapper>
);

EndButton.propTypes= {
    destination: PropTypes.string,
    children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
    ]).isRequired
}

export default EndButton;
