import React, { useState, useRef} from 'react';
import styled, { css } from 'styled-components';
import Link from 'components/Link/Link';
import LogoImg from 'assets/urbanLogo_1.png';
import useDetectMobile from '../../hooks/detectMobile';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  background-color: white;
  z-index: 999;
`;
const Logo = styled.img`
  height: 40px;
  object-fit: contain;
  margin: auto 0;
`;
const NavList = styled.div`
  width: 70%;
  margin: auto 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 780px) {
    width: 145px;
    height: 75px;
    display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 5%;
    background-color: white;
    box-shadow: 1px 1px 5px black;
  }
`;

const Hamburger = styled.div`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  margin: 0 30px;
`;

const HamburgerBox = styled.div`
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

const HamburgerInner = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  top: 50%;
  background-color: ${({ isExpanded }) => (isExpanded ? 'white' : 'black')};
  transition: background-color .1s  ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: black;
    left: 0;
    transition: all 0.4s ease-in-out;
  }
  &:before {
    
    top: -10px;
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        transform: translateY(10px) rotate(45deg);
        left: 5px;
      `}
  }
  &:after {
    
    bottom: -10px;
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        left: 5px;
        transform: translateY(-10px) rotate(-45deg);
      `}
  }
  }
`;

const Navigation = () => {
  const [isMobile, checkIfMobile] = useState(false);
  const [isExpanded, expandOrCollapse] = useState(false);
  const hamburgerNavRef = useRef(null)
  useDetectMobile(checkIfMobile);

  return (
    <Wrapper>
      <Logo src={LogoImg} />
      {isMobile && (
        <Hamburger onClick={() => expandOrCollapse(!isExpanded)}>
          <HamburgerBox>
            <HamburgerInner isExpanded={isExpanded} />
          </HamburgerBox>
        </Hamburger>
      )}
      <NavList isExpanded={isExpanded} ref={hamburgerNavRef}>
        <Link exact as={NavLink} to="/" activeclass="active">
          strona główna
        </Link>
        <Link as={NavLink} to="/portfolio" activeclass="active">
          wybrane realizacje
        </Link>
        <Link as={NavLink} to="/contact" activeclass="active">
          kontakt
        </Link>
      </NavList>
    </Wrapper>
  );
};
export default Navigation;
